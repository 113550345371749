import { schema } from 'yaschema';
import { makeWsApi } from 'yaschema-ws-api';

import { generalWsFailureSchemas } from '../../types/GeneralWsFailure';
import { conversationSchema } from './types/Conversation';
import { messageSchema } from './types/Message';

export const stream = makeWsApi({
  routeType: 'conversation-stream',
  url: '/conversations/stream',
  schemas: {
    requests: {
      ping: schema.string().allowEmptyString().optional(),
      authorize: schema.object({
        /** This is different than the default auth token.  See `GET /auth/streaming-token` */
        streamingAuthToken: schema.string()
      }),
      /** Doesn't include messages */
      streamConversations: schema.object({
        streamToken: schema.string().optional()
      }),
      streamConversationMessages: schema.object({
        conversationId: schema.string(),
        streamToken: schema.string().optional()
      }),
      stopStreamingConversations: schema.object({}),
      stopStreamingConversationMessages: schema.object({
        conversationId: schema.string()
      })
    },
    responses: {
      pong: schema.string().allowEmptyString().optional(),
      ...generalWsFailureSchemas,
      authorization: schema.object({
        authorized: schema.boolean()
      }),
      conversationDeleted: schema.object({
        conversationId: schema.string()
      }),
      conversations: schema.object({
        conversations: schema.array({ items: conversationSchema }),
        nextStreamToken: schema.string()
      }),
      conversationMessageDeleted: schema.object({
        conversationId: schema.string(),
        messageId: schema.string()
      }),
      conversationMessages: schema.object({
        conversationId: schema.string(),
        messages: schema.array({ items: messageSchema }),
        nextStreamToken: schema.string()
      }),
      conversationMessageSuggestions: schema.object({
        conversationId: schema.string(),
        /** The sequence number of the conversation these suggestions were based on, in case subsequent messages are added faster than new
         * suggestions are made */
        conversationSeq: schema.number(),
        suggestions: schema.array({ items: schema.string() })
      })
    }
  }
});
