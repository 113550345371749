import React, { useContext } from 'react';
import type { UseBindingEffectOptions } from 'react-bindings';
import { makeBinding, useBinding, useBindingEffect, useCallbackRef } from 'react-bindings';

const ConversationChangeContext = React.createContext(makeBinding<string>(() => '', { id: 'mostRecentlyChangedConversationId' }));

export const useConversationChangeEffect = (
  conversationIds: string | Set<string> | undefined,
  callback: (lastModifiedConversationId: string) => void,
  options?: Omit<UseBindingEffectOptions, 'detectInputChanges'>
) => {
  const callbackTriggerBinding = useBinding(() => '', { id: 'callbackTriggerBinding' });

  const isString = typeof conversationIds === 'string';

  const conversationChangeDetectionBinding = useContext(ConversationChangeContext);
  useBindingEffect(
    conversationChangeDetectionBinding,
    (id) => {
      if (conversationIds === undefined || (isString && conversationIds === id) || (!isString && conversationIds.has(id))) {
        callbackTriggerBinding.set(id);
      }
    },
    { limitType: 'none', deps: [isString] }
  );

  useBindingEffect(callbackTriggerBinding, (id) => callback(id), { ...options, detectInputChanges: false });
};

export const useConversationChangeNotifier = () => {
  const conversationChangeDetectionBinding = useContext(ConversationChangeContext);
  return useCallbackRef((conversationId: string) => conversationChangeDetectionBinding.set(conversationId));
};
