import { Box, Divider, ListItem, ListItemText } from '@mui/material';
import type { api } from 'msg-helper-demo-schema';
import { useRef } from 'react';
import type { Binding } from 'react-bindings';
import { BindingsConsumer, useBinding, useCallbackRef, useDerivedBinding } from 'react-bindings';
import ReactResizeDetector from 'react-resize-detector';
import AutoSizer from 'react-virtualized-auto-sizer';
import type { ListChildComponentProps } from 'react-window';
import { VariableSizeList } from 'react-window';

import { useConversationChangeEffect } from '../context/ConversationChange';
import { useConversationsCache } from '../context/ConversationsCache';
import { doSync } from '../utils/do-sync';
import { isDefined } from '../utils/is-defined';
import { ConversationListItem } from './ConversationListItem';
import { QuestionsConversationListItem } from './QuestionsConversationListItem';
import { Spinner } from './Spinner';

const ESTIMATED_ROW_HEIGHT = 72;

export interface ConversationListProps {
  selectedConversationId: Binding<string | undefined>;
}

export const ConversationList = ({ selectedConversationId }: ConversationListProps) => {
  const conversationsCache = useConversationsCache();

  const rebuildConversations = useBinding(() => undefined, { id: 'reloadConversations' });
  const isLoaded = useDerivedBinding(rebuildConversations, () => conversationsCache.isLoaded, {
    id: 'isLoaded',
    detectInputChanges: false
  });
  const conversations = useDerivedBinding(
    rebuildConversations,
    (): api.conversations.Conversation[] =>
      Object.values(conversationsCache.conversations)
        .filter(isDefined)
        .sort((a, b) => b.userSeq - a.userSeq),
    { id: 'conversations', detectInputChanges: false }
  );
  useConversationChangeEffect(undefined, () => rebuildConversations.set(undefined));

  const rowSizes = useBinding<number[]>(() => [], { id: 'rowSizes' });

  const listRef = useRef<VariableSizeList | null>(null);

  const onConversationClick = useCallbackRef(({ conversationId }: { conversationId: string }) =>
    selectedConversationId.set(conversationId)
  );

  return (
    <BindingsConsumer bindings={{ isLoaded, conversations }}>
      {({ isLoaded, conversations }) => (
        <AutoSizer>
          {({ height, width }) => (
            <VariableSizeList
              height={height}
              width={width}
              itemData={conversations}
              itemCount={1 + (isLoaded ? Math.max(1, conversations.length) : 1)}
              itemSize={(index) => rowSizes.get()[index] ?? ESTIMATED_ROW_HEIGHT}
              estimatedItemSize={ESTIMATED_ROW_HEIGHT}
              ref={listRef}
            >
              {(props: ListChildComponentProps<api.conversations.Conversation[]>) => {
                const conversations = props.data;

                return (
                  <ReactResizeDetector
                    handleWidth={false}
                    handleHeight={true}
                    onResize={(_width, height) => {
                      if (height !== undefined && rowSizes[props.index] !== height) {
                        const theRowSizes = rowSizes.get();
                        theRowSizes[props.index] = height;
                        rowSizes.set(theRowSizes);
                        listRef.current?.resetAfterIndex(props.index);
                      }
                    }}
                  >
                    {() => (
                      <Box sx={{ ...props.style, height: 'auto', width: '100%' }}>
                        {doSync(() => {
                          if (props.index === 0) {
                            return (
                              <>
                                <QuestionsConversationListItem
                                  selectedConversationId={selectedConversationId}
                                  onClick={onConversationClick}
                                />
                                <Divider />
                              </>
                            );
                          }

                          if (isLoaded) {
                            if (conversations.length === 0) {
                              return (
                                <ListItem>
                                  <ListItemText secondary="No conversations" />
                                </ListItem>
                              );
                            } else {
                              const conversation = conversations[props.index - 1];
                              return (
                                <ConversationListItem
                                  key={conversation.id}
                                  conversation={conversation}
                                  selectedConversationId={selectedConversationId}
                                  onClick={onConversationClick}
                                />
                              );
                            }
                          } else {
                            return <Spinner />;
                          }
                        })}
                      </Box>
                    )}
                  </ReactResizeDetector>
                );
              }}
            </VariableSizeList>
          )}
        </AutoSizer>
      )}
    </BindingsConsumer>
  );
};
