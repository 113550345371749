import { StatusCodes } from 'http-status-codes';
import { schema } from 'yaschema';
import { makeHttpApi } from 'yaschema-api';

import { genericFailureSchemas } from '../../types/GenericFailure';

// Ref: https://www.twilio.com/docs/messaging/guides/webhook-request
export const POST = makeHttpApi({
  method: 'POST',
  routeType: 'webhook',
  url: '/twilio/message',
  schemas: {
    request: {
      body: schema.object({
        MessageSid: schema.string(),
        AccountSid: schema.string(),
        MessagingServiceSid: schema.string().optional(),
        From: schema.string(),
        To: schema.string(),
        Body: schema.string(),
        NumMedia: schema.number().setAllowedSerializationForms(['number', 'string']),
        // Geographic Data-related Parameters
        FromCity: schema.string().allowEmptyString().optional(),
        FromState: schema.string().allowEmptyString().optional(),
        FromZip: schema.string().allowEmptyString().optional(),
        FromCountry: schema.string().allowEmptyString().optional(),
        ToCity: schema.string().allowEmptyString().optional(),
        ToState: schema.string().allowEmptyString().optional(),
        ToZip: schema.string().allowEmptyString().optional(),
        ToCountry: schema.string().allowEmptyString().optional(),
        // WhatsApp-specific Parameters
        ProfileName: schema.string().optional(),
        WaId: schema.string().optional(),
        Forwarded: schema.boolean().setAllowedSerializationForms(['boolean', 'string']).optional(),
        FrequentlyForwarded: schema.boolean().setAllowedSerializationForms(['boolean', 'string']).optional(),
        ButtonText: schema.string().optional(),
        // WhatsApp Location Parameters
        Latitude: schema.number().setAllowedSerializationForms(['number', 'string']).optional(),
        Longitude: schema.number().setAllowedSerializationForms(['number', 'string']).optional(),
        Address: schema.string().optional(),
        Label: schema.string().optional(),
        // WhatsApp Reply Parameters
        OriginalRepliedMessageSender: schema.string().optional(),
        OriginalRepliedMessageSid: schema.string().optional(),
        // Status Callback Parameters
        MessageStatus: schema
          .string('accepted', 'queued', 'sending', 'sent', 'failed', 'delivered', 'undelivered', 'receiving', 'received', 'read')
          .optional(),
        RawDlrDoneDate: schema.string().optional()
      })
    },
    successResponse: {
      status: schema.number(StatusCodes.OK),
      headers: schema.object({ 'content-type': schema.string('text/xml') }),
      body: schema.string()
    },
    failureResponse: genericFailureSchemas
  }
});
