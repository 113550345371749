import { useMediaQuery } from '@mui/material';
import type { Binding } from 'react-bindings';

export interface MediaSizeDetectorProps {
  query: string;
  matches: Binding<boolean>;
}

export const MediaSizeDetector = ({ query, matches }: MediaSizeDetectorProps) => {
  const value = useMediaQuery(query);

  matches.set(value);

  return <></>;
};
