import {
  setOnCommandRequestValidationErrorHandler,
  setOnCommandResponseValidationErrorHandler,
  setOnRequestValidationErrorHandler
} from 'yaschema-ws-api-client';

export const init = async () => {
  setOnRequestValidationErrorHandler(({ api, invalidPart, validationError }) => {
    console.warn(`WS request validation error for ${api.url}: ${invalidPart} - ${validationError}`);
  });

  setOnCommandRequestValidationErrorHandler(({ api, command, invalidPart, validationError }) => {
    console.warn(`WS request validation error for ${api.url}, command = ${JSON.stringify(command)}: ${invalidPart} - ${validationError}`);
  });

  setOnCommandResponseValidationErrorHandler(({ api, command, invalidPart, validationError }) => {
    console.warn(`WS response validation error for ${api.url}, command = ${JSON.stringify(command)}: ${invalidPart} - ${validationError}`);
  });
};
