import { appConfigDefaults } from './app-config-defaults';
import type { AppConfig } from './types/AppConfig';

export const development: AppConfig = {
  ...appConfigDefaults,

  CONFIG_ENV: 'development',

  BASE_URL_DEFAULT: 'https://api.1956.ai',
  BASE_URL_CONVERSATION_STREAM: 'wss://api.1956.ai',

  DEBUG_LOGGING: true
};
