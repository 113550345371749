import { ListItemButton, ListItemText } from '@mui/material';
import type { ReadonlyBinding } from 'react-bindings';
import { BindingsConsumer, useCallbackRef, useDerivedBinding } from 'react-bindings';

const specialId = 'special:questions';

export interface QuestionsConversationListItemProps {
  selectedConversationId: ReadonlyBinding<string | undefined>;
  onClick: (args: { conversationId: string }) => void;
}

export const QuestionsConversationListItem = ({ selectedConversationId, onClick }: QuestionsConversationListItemProps) => {
  const wrappedOnClick = useCallbackRef(() => onClick({ conversationId: specialId }));

  const isSelected = useDerivedBinding(selectedConversationId, (selectedConversationId) => selectedConversationId === specialId, {
    id: 'isSelected'
  });

  return (
    <BindingsConsumer bindings={isSelected}>
      {(isSelected) => (
        <ListItemButton onClick={wrappedOnClick} selected={isSelected}>
          <ListItemText
            primary="Questions"
            sx={{
              '& .MuiListItemText-primary': { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }
            }}
          />
        </ListItemButton>
      )}
    </BindingsConsumer>
  );
};
