import { Box, List, ListItem, ListItemText } from '@mui/material';
import type { api } from 'msg-helper-demo-schema';
import { useRef } from 'react';
import { BindingsConsumer, useBinding, useBindingEffect, useDerivedBinding } from 'react-bindings';
import ReactResizeDetector from 'react-resize-detector';
import AutoSizer from 'react-virtualized-auto-sizer';
import type { ListChildComponentProps } from 'react-window';
import { VariableSizeList } from 'react-window';

import { useConversationChangeEffect } from '../context/ConversationChange';
import { useConversationMessagesCache } from '../context/ConversationMessagesCache';
import { markConversationAsRead } from '../tasks/conversations/markConversationAsRead';
import { doAsync } from '../utils/do-async';
import { isDefined } from '../utils/is-defined';
import { ConversationMessage } from './ConversationMessage';
import { Spinner } from './Spinner';

const ESTIMATED_ROW_HEIGHT = 96;

export interface ConversationProps {
  conversationId: string;
}

export const Conversation = ({ conversationId }: ConversationProps) => {
  const conversationMessagesCache = useConversationMessagesCache();

  const rebuildMessages = useBinding(() => undefined, { id: 'rebuildMessages' });
  const isLoaded = useDerivedBinding(rebuildMessages, () => conversationMessagesCache[conversationId] !== undefined, {
    id: 'isLoaded',
    detectInputChanges: false,
    deps: [conversationId]
  });
  const messages = useDerivedBinding(
    rebuildMessages,
    (): api.conversations.Message[] =>
      Object.values(conversationMessagesCache[conversationId] ?? {})
        .filter(isDefined)
        .sort((a, b) => a.userSeq - b.userSeq),
    { id: 'messages', detectInputChanges: false, deps: [conversationId] }
  );
  useConversationChangeEffect(conversationId, () => rebuildMessages.set(undefined));

  const rowSizes = useBinding<number[]>(() => [], { id: 'rowSizes' });

  const listRef = useRef<VariableSizeList | null>(null);

  useBindingEffect(isLoaded, () => {
    doAsync(async () => {
      const marked = await markConversationAsRead({ conversationId });
      if (!marked.ok) {
        console.error('Something went wrong', marked);
      }
    });
  });

  useBindingEffect(messages, () => {
    setTimeout(() => {
      listRef.current?.scrollToItem(messages.get().length - 1, 'end');
    }, 500);

    doAsync(async () => {
      const marked = await markConversationAsRead({ conversationId });
      if (!marked.ok) {
        console.error('Something went wrong', marked);
      }
    });
  });

  return (
    <BindingsConsumer bindings={{ isLoaded, messages }}>
      {({ isLoaded, messages }) =>
        isLoaded ? (
          messages.length === 0 ? (
            <List>
              <ListItem>
                <ListItemText secondary="No messages" />
              </ListItem>
            </List>
          ) : (
            <AutoSizer>
              {({ height, width }) => (
                <VariableSizeList
                  height={height}
                  width={width}
                  itemData={messages}
                  itemCount={messages.length}
                  itemSize={(index) => rowSizes.get()[index] ?? ESTIMATED_ROW_HEIGHT}
                  estimatedItemSize={ESTIMATED_ROW_HEIGHT}
                  ref={listRef}
                >
                  {(props: ListChildComponentProps<api.conversations.Message[]>) => {
                    const messages = props.data;
                    const message = messages[props.index];
                    return (
                      <ReactResizeDetector
                        handleWidth={false}
                        handleHeight={true}
                        onResize={(_width, height) => {
                          if (height !== undefined && rowSizes[props.index] !== height) {
                            const theRowSizes = rowSizes.get();
                            theRowSizes[props.index] = height;
                            rowSizes.set(theRowSizes);
                            listRef.current?.resetAfterIndex(props.index);
                          }
                        }}
                      >
                        {() => (
                          <Box sx={{ ...props.style, height: 'auto', width: '100%' }}>
                            <ConversationMessage key={message.id} message={message} />
                          </Box>
                        )}
                      </ReactResizeDetector>
                    );
                  }}
                </VariableSizeList>
              )}
            </AutoSizer>
          )
        ) : (
          <Spinner />
        )
      }
    </BindingsConsumer>
  );
};
