import { api } from 'msg-helper-demo-schema';
import type { WrappedResult } from 'react-waitables';
import { apiFetch } from 'yaschema-api-fetcher';

export const markConversationAsRead = async ({ conversationId }: { conversationId: string }): Promise<WrappedResult<undefined>> => {
  const res = await apiFetch(api.conversations.id.markAsRead.POST, { params: { conversationId } });
  if (!res.ok) {
    return { ok: false, value: res };
  }

  return { ok: true, value: undefined };
};
