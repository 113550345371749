import { appConfigDefaults } from './app-config-defaults';
import type { AppConfig } from './types/AppConfig';

export const local: AppConfig = {
  ...appConfigDefaults,

  CONFIG_ENV: 'local',

  BASE_URL_DEFAULT: 'http://local.1956.ai:8080',
  BASE_URL_CONVERSATION_STREAM: 'ws://local.1956.ai:8080',

  DEBUG_LOGGING: true
};
