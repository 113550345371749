import { useEffect, useRef } from 'react';
import type { LimiterOptions, TypeOrDeferredType } from 'react-bindings';
import { resolveTypeOrDeferredType, useBindingEffect, useLimiter } from 'react-bindings';
import type { UseWaitableArgs } from 'react-waitables';
import { useWaitableFunction } from 'react-waitables';
import type { OptionalIfPossiblyUndefined } from 'yaschema-api';
import type { AnyCommands, AnyQuery, WsApi } from 'yaschema-ws-api';
import type { ApiWs, ApiWsOptions, WsApiResponseHandlers } from 'yaschema-ws-api-client';
import { apiWs } from 'yaschema-ws-api-client';

import { ONE_SEC_MSEC } from '../consts/time';
import { normalizeAsArray } from '../utils/normalize-as-array';
import { useIsMountedBinding } from './use-is-mounted-binding';

// TODO: move to its own library
/** Wraps a WebSocket connection in a waitable and automatically reconnects whenever the waitable is mounted and unlocked */
export const useApiWs = <RequestCommandsT extends AnyCommands, ResponseCommandsT extends AnyCommands, QueryT extends AnyQuery>(
  api: WsApi<RequestCommandsT, ResponseCommandsT, QueryT>,
  req: TypeOrDeferredType<OptionalIfPossiblyUndefined<'query', QueryT>>,
  responseHandlers: TypeOrDeferredType<Partial<WsApiResponseHandlers<RequestCommandsT, ResponseCommandsT, QueryT>>>,
  options: TypeOrDeferredType<ApiWsOptions<RequestCommandsT, QueryT>> = {},
  waitableArgs: Omit<UseWaitableArgs<ApiWs<RequestCommandsT, ResponseCommandsT, QueryT>>, 'id'> & { id?: string } = {}
) => {
  const { limitMode, limitMSec, limitType, priority, queue } = waitableArgs;
  const limiterOptions: LimiterOptions = { limitMode, limitMSec, limitType, priority, queue };

  const isMounted = useIsMountedBinding();

  const retryCount = useRef(0);

  const connection = useWaitableFunction(
    async () => {
      try {
        const resolvedOptions = resolveTypeOrDeferredType(options);

        await new Promise((resolve) => setTimeout(resolve, Math.min(retryCount.current * 500, 5 * ONE_SEC_MSEC)));
        retryCount.current += 1;

        return {
          ok: true,
          value: await apiWs<RequestCommandsT, ResponseCommandsT, QueryT>(
            api,
            resolveTypeOrDeferredType(req),
            resolveTypeOrDeferredType(responseHandlers),
            {
              ...resolvedOptions,
              onConnect: async (fwd) => {
                retryCount.current = 0;

                await resolvedOptions.onConnect?.(fwd);
              },
              onDisconnect: async (fwd) => {
                await resolvedOptions.onDisconnect?.(fwd);

                connection.reset('soft');
              }
            }
          )
        };
      } catch (e) {
        return { ok: false, value: e };
      }
    },
    {
      id: 'connection',
      ...waitableArgs,
      deps: [api, ...(waitableArgs.deps ?? [])],
      lockedUntil: [isMounted, ...normalizeAsArray(waitableArgs.lockedUntil ?? [])]
    }
  );

  const updateConnection = () => {
    if (connection.isBusy.get()) {
      return; // Not ready
    }

    const theConnection = connection.value.get();
    const readyState = theConnection?.ws.readyState;

    if (connection.isLocked.get()) {
      // If the connection is locked, that means we don't want a connection
      if (readyState !== WebSocket.CLOSING && readyState !== WebSocket.CLOSED) {
        connection.reset('hard');
        theConnection?.ws.close();
      }
    } else if (readyState !== WebSocket.CONNECTING && readyState !== WebSocket.OPEN) {
      // If the connection is unlocked, that means we do want a connection
      connection.reset('hard');
    }
  };

  const connectionUpdateLimiter = useLimiter({ id: 'connectionUpdateLimiter', ...limiterOptions });

  useBindingEffect(connection.isBusy, () => connectionUpdateLimiter.limit(updateConnection));

  useEffect(() => {
    connectionUpdateLimiter.limit(updateConnection);

    return () => {
      connectionUpdateLimiter.limit(updateConnection);
    };
  });

  return connection;
};
