import type { SwitchProps } from '@mui/material';
import { Switch } from '@mui/material';
import type { Binding } from 'react-bindings';
import { BindingsConsumer, useCallbackRef } from 'react-bindings';

export type ControlledSwitchProps = SwitchProps & {
  value: Binding<boolean>;
};

export const ControlledSwitch = ({ value, ...fwd }: ControlledSwitchProps) => {
  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallbackRef((event) => {
    value.set(event.target.checked);
  });

  return (
    <BindingsConsumer bindings={value} limitType="none">
      {(value) => <Switch {...fwd} value={value} onChange={onChange} />}
    </BindingsConsumer>
  );
};
