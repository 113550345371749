import { api } from 'msg-helper-demo-schema';
import type { WrappedResult } from 'react-waitables';
import { apiFetch } from 'yaschema-api-fetcher';

export const verifyTotp = async ({ totpId, passcode }: { totpId: string; passcode: string }): Promise<WrappedResult<undefined>> => {
  const res = await apiFetch(api.auth.mfa.verify.POST, { body: { totpId, passcode } });

  if (!res.ok) {
    console.error(res);
    return { ok: false, value: undefined };
  }

  return { ok: true, value: undefined };
};
