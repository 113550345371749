import { StatusCodes } from 'http-status-codes';
import { schema } from 'yaschema';
import { makeHttpApi } from 'yaschema-api';

import { genericFailureSchemas } from '../../types/GenericFailure';
import { supportedLocaleSchema } from '../../types/SupportedLocale';
import { questionSchema } from './types/Question';

export const getQuestionsQuerySchema = schema.object({
  /** @defaultValue `"en-US"` */
  locale: supportedLocaleSchema.optional()
});
export type GetQuestionsQuery = typeof getQuestionsQuerySchema.valueType;

export const GET = makeHttpApi({
  method: 'GET',
  routeType: 'questions',
  url: '/questions',
  isSafeToRetry: true,
  cachePolicy: { canCache: 'public', cacheIntervalSec: 900 },
  schemas: {
    request: {
      query: getQuestionsQuerySchema.optional()
    },
    successResponse: {
      status: schema.number(StatusCodes.OK),
      body: schema.object({
        questions: schema.array({ items: questionSchema })
      })
    },
    failureResponse: genericFailureSchemas
  }
});
