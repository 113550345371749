import { schema } from 'yaschema';

import { conversationParticipantSchema } from './ConversationParticipant';

export const conversationSchema = schema.object({
  id: schema.string(),
  createdAtTimeMSec: schema.number(),
  updatedAtTimeMSec: schema.number(),
  deletedAtTimeMSec: schema.number().allowNull(),
  participants: schema.array({ items: conversationParticipantSchema }),
  summary: schema.string().allowEmptyString(),
  numUnreadMessages: schema.number(),
  userSeq: schema.number()
});
export type Conversation = typeof conversationSchema.valueType;
