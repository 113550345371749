import type { api } from 'msg-helper-demo-schema';
import type { PropsWithChildren } from 'react';
import React, { useContext } from 'react';
import { makeBinding, makeConstBinding } from 'react-bindings';
import { useWaitableFunction } from 'react-waitables';

import { checkAuth } from '../tasks/auth/checkAuth';

const AuthCheckContext = React.createContext(makeConstBinding<api.auth.AuthCheck | undefined>(() => undefined, { id: 'defaultAuthCheck' }));

const hardRecheckAuthSignal = makeBinding(() => undefined, { id: 'hardRecheckAuthSignal' });
const softRecheckAuthSignal = makeBinding(() => undefined, { id: 'softRecheckAuthSignal' });

/** Call if the auth state has likely changed, ex. after attempting sign in */
export const recheckAuth = (mode: 'hard' | 'soft') => {
  switch (mode) {
    case 'hard':
      hardRecheckAuthSignal.set(undefined);
      break;
    case 'soft':
      softRecheckAuthSignal.set(undefined);
      break;
  }
};

export const useAuthenticationCheck = () => useContext(AuthCheckContext);

export const AuthCheckProvider = ({ children }: PropsWithChildren) => {
  const authCheck = useWaitableFunction(checkAuth, {
    id: 'authCheck',
    softResetBindings: softRecheckAuthSignal,
    hardResetBindings: hardRecheckAuthSignal
  });

  return <AuthCheckContext.Provider value={authCheck.value}>{children}</AuthCheckContext.Provider>;
};
