import { Box, Button, Card, Grid, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import type { FormEvent } from 'react';
import { useBinding, useCallbackRef } from 'react-bindings';

import { ControlledTextField } from '../components/ControlledTextField';
import { recheckAuth } from '../context/Authentication';
import { signIn } from '../tasks/auth/signIn';
import { doAsync } from '../utils/do-async';

export const SignInScreen = () => {
  const { enqueueSnackbar } = useSnackbar();

  const username = useBinding<string>(() => '', { id: 'username', detectChanges: true });
  const password = useBinding<string>(() => '', { id: 'password', detectChanges: true });

  const submit = useCallbackRef(() =>
    doAsync(async () => {
      const signedIn = await signIn({ username: username.get(), password: password.get() });
      if (!signedIn.ok) {
        username.set('');
        password.set('');

        enqueueSnackbar({ message: 'Login Incorrect', variant: 'error' });

        return;
      }

      recheckAuth('hard');
    })
  );

  const onSubmit = useCallbackRef((event: FormEvent) => {
    event.preventDefault();
    submit();
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 2, padding: 2, flexGrow: 1, maxWidth: '100%' }}>
      <Card variant="outlined" sx={{ padding: 2, width: '100%', maxWidth: '960px' }}>
        <form onSubmit={onSubmit}>
          <Grid container spacing={2} sx={{ alignItems: 'center' }}>
            <Grid item xs={4} md={2}>
              <Typography variant="body1">Username</Typography>
            </Grid>
            <Grid item xs={8} md={4} sx={{ display: 'flex' }}>
              <ControlledTextField value={username} sx={{ flexGrow: 1 }} />
            </Grid>
            <Grid item xs={4} md={2}>
              <Typography variant="body1">Password</Typography>
            </Grid>
            <Grid item xs={8} md={4} sx={{ display: 'flex' }}>
              <ControlledTextField value={password} type="password" sx={{ flexGrow: 1 }} />
            </Grid>
          </Grid>

          <Box sx={{ display: 'flex', paddingTop: 2, width: '100%', justifyContent: 'flex-end' }}>
            <Button type="submit" variant="contained" sx={{ minWidth: '10em' }}>
              Sign In
            </Button>
          </Box>
        </form>
      </Card>
    </Box>
  );
};
