import {
  setDefaultShouldRetryEvaluator,
  setOnRequestValidationErrorHandler,
  setOnResponseValidationErrorHandler
} from 'yaschema-api-fetcher';

import { ONE_SEC_MSEC } from '../../consts/time';

export const init = async () => {
  setOnRequestValidationErrorHandler(({ api, invalidPart, validationError }) => {
    console.warn(`HTTP request validation error for ${api.url}: ${invalidPart} - ${validationError}`);
  });

  setOnResponseValidationErrorHandler(({ api, invalidPart, validationError }) => {
    console.warn(`HTTP response validation error for ${api.url}: ${invalidPart} - ${validationError}`);
  });

  setDefaultShouldRetryEvaluator(async ({ api, retryCount }) => {
    if (!api.isSafeToRetry) {
      return false;
    }

    return { afterDelayMSec: Math.min(retryCount * 300, 30 * ONE_SEC_MSEC) };
  });
};
