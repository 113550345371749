import { StatusCodes } from 'http-status-codes';
import { schema } from 'yaschema';
import { makeHttpApi } from 'yaschema-api';

import { genericFailureSchemas } from '../../types/GenericFailure';
import { optionalAuthHeadersSchema } from './types/AuthHeaders';

export const DEL = makeHttpApi({
  method: 'DELETE',
  routeType: 'auth',
  credentials: 'include',
  url: '/auth',
  schemas: {
    request: {
      headers: optionalAuthHeadersSchema
    },
    successResponse: {
      status: schema.number(StatusCodes.OK)
    },
    failureResponse: genericFailureSchemas
  }
});
