import { api } from 'msg-helper-demo-schema';
import type { WrappedResult } from 'react-waitables';
import { apiFetch } from 'yaschema-api-fetcher';

export const createTotp = async (): Promise<WrappedResult<{ totpId: string; secret: string }>> => {
  const res = await apiFetch(api.auth.mfa.create.POST, {});

  if (!res.ok) {
    return { ok: false, value: undefined };
  }

  return { ok: true, value: res.body };
};
