import { Box } from '@mui/material';
import type { api } from 'msg-helper-demo-schema';
import { useRef } from 'react';
import type { Binding } from 'react-bindings';
import { useBinding, useBindingEffect } from 'react-bindings';
import ReactResizeDetector from 'react-resize-detector';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useWaitableFunction, WaitablesConsumer } from 'react-waitables';
import type { ListChildComponentProps } from 'react-window';
import { VariableSizeList } from 'react-window';

import { listAnswers } from '../tasks/questions/listAnswers';
import { listQuestions } from '../tasks/questions/listQuestions';
import { QuestionListItem } from './QuestionListItem';
import { Spinner } from './Spinner';

const ESTIMATED_ROW_HEIGHT = 104;

export interface QuestionsListProps {
  answers: Binding<Partial<Record<string, string>>>;
}

export const QuestionsList = ({ answers }: QuestionsListProps) => {
  const questions = useWaitableFunction(listQuestions({ locale: 'en-US' }), { id: 'questions' });
  const previousAnswers = useWaitableFunction(listAnswers, { id: 'previousAnswers' });

  const rowSizes = useBinding<number[]>(() => [], { id: 'rowSizes' });

  const listRef = useRef<VariableSizeList | null>(null);

  useBindingEffect(
    previousAnswers.value,
    (previousAnswers) => {
      if (previousAnswers === undefined) {
        return; // Not ready
      }

      answers.set(previousAnswers);
    },
    { limitType: 'none' }
  );

  return (
    <WaitablesConsumer dependencies={{ questions, previousAnswers }} ifLoading={() => <Spinner />}>
      {({ questions }) => (
        <AutoSizer>
          {({ height, width }) => (
            <VariableSizeList
              height={height}
              width={width}
              itemData={questions}
              itemCount={questions.length}
              itemSize={(index) => rowSizes.get()[index] ?? ESTIMATED_ROW_HEIGHT}
              estimatedItemSize={ESTIMATED_ROW_HEIGHT}
              ref={listRef}
            >
              {(props: ListChildComponentProps<api.questions.Question[]>) => {
                const questions = props.data;
                const question = questions[props.index];

                return (
                  <ReactResizeDetector
                    handleWidth={false}
                    handleHeight={true}
                    onResize={(_width, height) => {
                      if (height !== undefined && rowSizes[props.index] !== height) {
                        const theRowSizes = rowSizes.get();
                        theRowSizes[props.index] = height;
                        rowSizes.set(theRowSizes);
                        listRef.current?.resetAfterIndex(props.index);
                      }
                    }}
                  >
                    {() => (
                      <Box sx={{ ...props.style, height: 'auto', width: '100%' }}>
                        <QuestionListItem question={question} answers={answers} />
                      </Box>
                    )}
                  </ReactResizeDetector>
                );
              }}
            </VariableSizeList>
          )}
        </AutoSizer>
      )}
    </WaitablesConsumer>
  );
};
