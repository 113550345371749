import { Badge, ListItemButton, ListItemText } from '@mui/material';
import type { api } from 'msg-helper-demo-schema';
import type { ReadonlyBinding } from 'react-bindings';
import { BindingsConsumer, useCallbackRef, useDerivedBinding } from 'react-bindings';

export interface ConversationListItemProps {
  conversation: api.conversations.Conversation;
  selectedConversationId: ReadonlyBinding<string | undefined>;
  onClick: (args: { conversationId: string }) => void;
}

export const ConversationListItem = ({ conversation, selectedConversationId, onClick }: ConversationListItemProps) => {
  const wrappedOnClick = useCallbackRef(() => onClick({ conversationId: conversation.id }));

  const isSelected = useDerivedBinding(selectedConversationId, (selectedConversationId) => selectedConversationId === conversation.id, {
    id: 'isSelected'
  });

  return (
    <BindingsConsumer bindings={isSelected}>
      {(isSelected) => (
        <ListItemButton onClick={wrappedOnClick} selected={isSelected}>
          <ListItemText
            primary={conversation.participants.map((p) => p.display).join(', ')}
            secondary={conversation.summary}
            sx={{
              '& .MuiListItemText-primary': { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }
            }}
          />
          {conversation.numUnreadMessages > 0 ? (
            <Badge
              badgeContent={conversation.numUnreadMessages}
              color="primary"
              sx={{ position: 'relative', '& .MuiBadge-badge': { position: 'relative', transform: 'none' } }}
            />
          ) : null}
        </ListItemButton>
      )}
    </BindingsConsumer>
  );
};
