import type { FormEvent } from 'react';
import { useBinding, useCallbackRef } from 'react-bindings';

import { updateParticipantDisplay } from '../tasks/conversations/updateParticipantDisplay';
import { doAsync } from '../utils/do-async';
import { ControlledTextField } from './ControlledTextField';

export interface ConversationParticipantDisplayTextFieldProps {
  conversationId: string;
  display: string;
  primary: string;
}

export const ConversationParticipantDisplayTextField = ({
  conversationId,
  display,
  primary
}: ConversationParticipantDisplayTextFieldProps) => {
  const editDisplay = useBinding(() => (display.trim() === primary.trim() ? '' : display), {
    id: 'editDisplay',
    detectChanges: true,
    deps: [display, primary]
  });

  const save = useCallbackRef(() => {
    if (!editDisplay.isModified()) {
      return; // Not modified
    }

    editDisplay.setIsModified(false);

    doAsync(async () => {
      const newDisplay = editDisplay.get().trim();

      const saved = await updateParticipantDisplay({
        conversationId,
        participantPrimary: primary,
        newParticipantDisplay: newDisplay.length === 0 ? primary : newDisplay
      });
      if (!saved.ok) {
        // TODO: TEMP
        console.error('Failed to save change');
      }
    });
  });

  const wrappedOnSubmit = useCallbackRef((event: FormEvent) => {
    event.preventDefault();
    save();
  });

  return (
    <form onSubmit={wrappedOnSubmit}>
      <ControlledTextField
        value={editDisplay}
        onBlur={save}
        variant="standard"
        placeholder="Display Name"
        sx={{
          flexGrow: 1,
          overflow: 'hidden',
          height: '36px',
          '& .MuiInputBase-root': { overflow: 'hidden', color: 'white' }
        }}
        hiddenLabel={true}
      />
    </form>
  );
};
