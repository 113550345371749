import { ListItem, ListItemText, useTheme } from '@mui/material';
import type { api } from 'msg-helper-demo-schema';

export interface ConversationMessageProps {
  message: api.conversations.Message;
}

export const ConversationMessage = ({ message }: ConversationMessageProps) => {
  const theme = useTheme();

  const isFromSelf = message.sender === null;

  return (
    <ListItem sx={{ flexShrink: 1, flexBasis: 0 }}>
      <ListItemText
        primary={message.content}
        secondary={new Date(message.timeMSec).toISOString()}
        sx={{
          backgroundColor: isFromSelf ? theme.palette.primary.main : theme.palette.secondary.main,
          color: isFromSelf ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText,
          borderRadius: '4px',
          padding: '12px 20px',
          boxSizing: 'border-box',
          overflow: 'hidden',
          flexShrink: 1,
          flexBasis: 0
        }}
      />
    </ListItem>
  );
};
