import { development } from './development';
import { local } from './local';
import { production } from './production';
import type { AppConfig } from './types/AppConfig';

export const appConfigs: Partial<Record<string, AppConfig>> = {
  development,
  local,
  production
};
