import { Chip, Toolbar } from '@mui/material';
import type { Binding } from 'react-bindings';
import { BindingsConsumer, useBinding, useDerivedBinding } from 'react-bindings';

import { useConversationChangeEffect } from '../context/ConversationChange';
import { useConversationMessageSuggestionsCache } from '../context/ConversationMessageSuggestionsCache';
import { useConversationsCache } from '../context/ConversationsCache';

export interface InputSuggestionsBarProps {
  input: Binding<string>;
  conversationId: string;
}

export const InputSuggestionsBar = ({ input, conversationId }: InputSuggestionsBarProps) => {
  const conversationsCache = useConversationsCache();
  const messageSuggestionsCache = useConversationMessageSuggestionsCache();

  const rebuildSuggestions = useBinding(() => undefined, { id: 'rebuildSuggestions' });
  const suggestions = useDerivedBinding(
    rebuildSuggestions,
    (): string[] => {
      if (messageSuggestionsCache[conversationId]?.conversationSeq !== conversationsCache.conversations[conversationId]?.userSeq) {
        return [];
      }

      return messageSuggestionsCache[conversationId]?.suggestions ?? [];
    },
    { id: 'messages', detectInputChanges: false, deps: [conversationId] }
  );
  useConversationChangeEffect(conversationId, () => rebuildSuggestions.set(undefined));

  const onSuggestionClick = (suggestion: string) => () => input.set(suggestion);

  return (
    <BindingsConsumer bindings={suggestions}>
      {(suggestions) =>
        suggestions.length > 0 ? (
          <Toolbar sx={{ alignItems: 'center', overflowX: 'auto' }}>
            {suggestions.map((suggestion, index) => (
              <Chip
                key={index}
                variant="outlined"
                label={suggestion}
                onClick={onSuggestionClick(suggestion)}
                sx={{ marginLeft: index > 0 ? '8px' : undefined }}
              />
            ))}
          </Toolbar>
        ) : null
      }
    </BindingsConsumer>
  );
};
