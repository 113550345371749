import { api } from 'msg-helper-demo-schema';
import type { WrappedResult } from 'react-waitables';
import { apiFetch } from 'yaschema-api-fetcher';

export const listAnswers = async (): Promise<WrappedResult<Partial<Record<string, string>>>> => {
  const res = await apiFetch(api.questions.answers.GET, {});

  if (!res.ok) {
    console.error(res);
    return { ok: false, value: undefined };
  }

  return { ok: true, value: res.body.answers };
};
