import { schema } from 'yaschema';

export const messageSchema = schema.object({
  id: schema.string(),
  /** From self = `null`, otherwise this will be a participant primary */
  sender: schema.string().allowNull(),
  timeMSec: schema.number(),
  deletedAtTimeMSec: schema.number().allowNull(),
  content: schema.string(),
  attachmentUrls: schema.array({ items: schema.string() }).optional(),
  userSeq: schema.number()
});
export type Message = typeof messageSchema.valueType;
