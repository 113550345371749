import { BindingsConsumer } from 'react-bindings';

import { useAuthenticationCheck } from '../context/Authentication';
import { LoadingScreen } from '../screens/LoadingScreen';
import { AuthenticatedRootRoute } from './AuthenticatedRootRoute';
import { PartiallyAuthenticatedRootRoute } from './PartiallyAuthenticatedRootRoute';
import { UnauthenticatedRootRoute } from './UnauthenticatedRootRoute';

export const RootRoute = () => {
  const authCheck = useAuthenticationCheck();

  return (
    <BindingsConsumer bindings={authCheck}>
      {(authCheck) => {
        if (authCheck === undefined) {
          return <LoadingScreen />;
        }

        if (authCheck.sessionId !== undefined) {
          if (authCheck.requiresMfa === false) {
            return <AuthenticatedRootRoute />;
          } else {
            return <PartiallyAuthenticatedRootRoute />;
          }
        }

        return <UnauthenticatedRootRoute />;
      }}
    </BindingsConsumer>
  );
};
