import { api } from 'msg-helper-demo-schema';
import type { WrappedResult } from 'react-waitables';
import { apiFetch } from 'yaschema-api-fetcher';

export const createConversation = async ({
  participants,
  content
}: {
  participants: api.conversations.ConversationParticipant[];
  content: string;
}): Promise<WrappedResult<{ conversationId: string }>> => {
  const res = await apiFetch(api.conversations.POST, {
    body: {
      participants,
      content
    }
  });
  if (!res.ok) {
    return { ok: false, value: res };
  }

  return { ok: true, value: { conversationId: res.body.conversationId } };
};
