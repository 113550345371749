import { api } from 'msg-helper-demo-schema';
import type { WrappedResult } from 'react-waitables';
import { apiFetch } from 'yaschema-api-fetcher';

export const activateTotp = async ({
  totpId,
  name,
  passcode
}: {
  totpId: string;
  name: string;
  passcode: string;
}): Promise<WrappedResult<undefined>> => {
  const res = await apiFetch(api.auth.mfa.activate.POST, { body: { totpId, name, passcode } });

  if (!res.ok) {
    console.error(res);
    return { ok: false, value: undefined };
  }

  return { ok: true, value: undefined };
};
