import { schema } from 'yaschema';

export const questionPurposeSchema = schema.string(
  'job',
  'personal-attribute',
  'personality',
  'formal-conversation-style',
  'casual-conversation-style'
);
export type QuestionPurpose = typeof questionPurposeSchema.valueType;
