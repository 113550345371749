import { StatusCodes } from 'http-status-codes';
import { schema } from 'yaschema';
import { makeHttpApi } from 'yaschema-api';

import { genericFailureSchemas } from '../../../types/GenericFailure';
import { optionalAuthHeadersSchema } from '../../auth/types/AuthHeaders';

export const DEL = makeHttpApi({
  method: 'DELETE',
  routeType: 'conversation',
  credentials: 'include',
  url: '/conversations/{conversationId}',
  schemas: {
    request: {
      headers: optionalAuthHeadersSchema,
      params: schema.object({ conversationId: schema.string() })
    },
    successResponse: {
      status: schema.number(StatusCodes.OK),
      headers: optionalAuthHeadersSchema
    },
    failureResponse: genericFailureSchemas
  }
});
