import { schema } from 'yaschema';

import { questionPurposeSchema } from './QuestionPurpose';

export const questionSchema = schema.object({
  key: schema.string(),
  purpose: questionPurposeSchema,
  localizedQuestion: schema.string(),
  localizedInstructions: schema.string().optional()
});
export type Question = typeof questionSchema.valueType;
