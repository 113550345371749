import type { WrappedResult } from 'react-waitables';

export const makeAsyncUncheckedAccess =
  <ArgsT extends any[], T, F>(func: (...args: ArgsT) => Promise<WrappedResult<T, F>>) =>
  async (...args: ArgsT) => {
    const result = await func(...args);
    if (!result.ok) {
      throw result;
    }

    return result.value;
  };
