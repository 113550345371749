import { setDefaultUrlBase, setUrlBaseForRouteType } from 'yaschema-api';

import { getUncheckedAppConfig } from '../get-app-config';

export const init = async () => {
  const appConfig = await getUncheckedAppConfig();

  setDefaultUrlBase(appConfig.BASE_URL_DEFAULT);
  setUrlBaseForRouteType('conversation-stream', appConfig.BASE_URL_CONVERSATION_STREAM);

  if (appConfig.BASE_URL_AUTH !== undefined) {
    setUrlBaseForRouteType('auth', appConfig.BASE_URL_AUTH);
  }

  if (appConfig.BASE_URL_CONVERSATION !== undefined) {
    setUrlBaseForRouteType('conversation', appConfig.BASE_URL_CONVERSATION);
  }

  if (appConfig.BASE_URL_REST !== undefined) {
    setUrlBaseForRouteType('rest', appConfig.BASE_URL_REST);
  }
};
