import { ThemeProvider } from '@mui/material';
import _ from 'lodash';
import { useEffect } from 'react';
import { BindingsConsumer, useBinding } from 'react-bindings';

import { init } from './config/init';
import { AuthCheckProvider } from './context/Authentication';
import { RootRoute } from './Routes/RootRoute';
import { LoadingScreen } from './screens/LoadingScreen';
import { theme } from './theme';
import { doAsync } from './utils/do-async';

const App = () => {
  const isInitted = useBinding(() => false, { id: 'isInitted', detectChanges: true });

  useEffect(() => {
    doAsync(async () => {
      await init();
      isInitted.set(true);
    });
  });

  return (
    <BindingsConsumer bindings={isInitted}>
      {(isInitted) =>
        isInitted ? (
          <AuthCheckProvider>
            <ThemeProvider theme={theme}>
              <RootRoute />
            </ThemeProvider>
          </AuthCheckProvider>
        ) : (
          <LoadingScreen />
        )
      }
    </BindingsConsumer>
  );
};

export default App;
