import { schema } from 'yaschema';

export const generalWsFailureSchema = schema.object({ message: schema.string().allowEmptyString().optional() });
export type GeneralWsFailure = typeof generalWsFailureSchema.valueType;

export const generalWsFailureSchemas = {
  badRequest: generalWsFailureSchema,
  forbidden: generalWsFailureSchema,
  internalServerError: generalWsFailureSchema,
  notFound: generalWsFailureSchema,
  unauthorized: generalWsFailureSchema
};
export interface GeneralWsFailures {
  badRequest: GeneralWsFailure;
  forbidden: GeneralWsFailure;
  internalServerError: GeneralWsFailure;
  notFound: GeneralWsFailure;
  unauthorized: GeneralWsFailure;
}
