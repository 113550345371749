import type { SupportedLocale } from 'msg-helper-demo-schema';
import { api } from 'msg-helper-demo-schema';
import type { WrappedResult } from 'react-waitables';
import { apiFetch } from 'yaschema-api-fetcher';

export const listQuestions =
  ({ locale }: { locale: SupportedLocale }) =>
  async (): Promise<WrappedResult<api.questions.Question[]>> => {
    const res = await apiFetch(api.questions.GET, { query: { locale } });

    if (!res.ok) {
      console.error(res);
      return { ok: false, value: undefined };
    }

    return { ok: true, value: res.body.questions };
  };
