import { StatusCodes } from 'http-status-codes';
import { schema } from 'yaschema';
import { makeHttpApi } from 'yaschema-api';

import { genericFailureSchemas } from '../../../types/GenericFailure';
import { optionalAuthHeadersSchema } from '../../auth/types/AuthHeaders';

export const GET = makeHttpApi({
  method: 'GET',
  routeType: 'questions',
  credentials: 'include',
  url: '/questions/answers',
  isSafeToRetry: true,
  schemas: {
    request: {},
    successResponse: {
      status: schema.number(StatusCodes.OK),
      headers: optionalAuthHeadersSchema,
      body: schema.object({
        answers: schema.record(schema.string(), schema.string().allowEmptyString())
      })
    },
    failureResponse: genericFailureSchemas
  }
});
