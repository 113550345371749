import { api } from 'msg-helper-demo-schema';
import type { WrappedResult } from 'react-waitables';
import { apiFetch } from 'yaschema-api-fetcher';

export const getStreamingToken = async (): Promise<WrappedResult<string>> => {
  const res = await apiFetch(api.auth.streamingToken.GET, {});
  if (!res.ok) {
    return { ok: false, value: res };
  }

  return { ok: true, value: res.body.streamingAuthToken };
};
