import { schema } from 'yaschema';

export const appConfigSchema = schema.object({
  CONFIG_ENV: schema.string('development', 'local', 'production'),

  BASE_URL_DEFAULT: schema.string(),
  BASE_URL_REST: schema.string().optional(),
  BASE_URL_AUTH: schema.string().optional(),
  BASE_URL_CONVERSATION: schema.string().optional(),
  BASE_URL_CONVERSATION_STREAM: schema.string(),

  DEBUG_LOGGING: schema.boolean().setAllowedSerializationForms(['boolean', 'string'])
});
export type AppConfig = typeof appConfigSchema.valueType;
