import { StatusCodes } from 'http-status-codes';
import { api } from 'msg-helper-demo-schema';
import type { WrappedResult } from 'react-waitables';
import { apiFetch } from 'yaschema-api-fetcher';

export const checkAuth = async (): Promise<WrappedResult<api.auth.AuthCheck>> => {
  const res = await apiFetch(api.auth.check.GET, {});

  if (!res.ok) {
    if (res.status === StatusCodes.UNAUTHORIZED) {
      return { ok: true, value: {} };
    }

    console.error(res);
    return { ok: false, value: undefined };
  }

  return { ok: true, value: res.body };
};
