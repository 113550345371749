import { Box, Button, Toolbar } from '@mui/material';
import type { FormEvent } from 'react';
import type { Binding } from 'react-bindings';
import { useCallbackRef } from 'react-bindings';

import { ControlledTextField } from './ControlledTextField';

export interface TextInputBarProps {
  input: Binding<string>;
  onSubmit: (value: string) => void;
}

export const TextInputBar = ({ input, onSubmit }: TextInputBarProps) => {
  const onSendClick = useCallbackRef(() => {
    const theInput = input.get().trim();
    if (theInput.length === 0) {
      return; // Nothing to do
    }

    onSubmit(theInput);
  });

  const wrappedOnSubmit = useCallbackRef((event: FormEvent) => {
    event.preventDefault();
    onSendClick();
  });

  return (
    <form onSubmit={wrappedOnSubmit}>
      <Toolbar sx={{ alignItems: 'center' }}>
        <ControlledTextField
          value={input}
          variant="filled"
          placeholder="Enter a message"
          sx={{
            flexGrow: 1,
            overflow: 'hidden',
            height: '36px',
            '& .MuiInputBase-root': { overflow: 'hidden', backgroundColor: 'white', borderRadius: '4px' }
          }}
          hiddenLabel={true}
        />
        <Box sx={{ width: '12px' }} />
        <Button color="primary" variant="contained" onClick={onSendClick}>
          Send
        </Button>
      </Toolbar>
    </form>
  );
};
