import { StatusCodes } from 'http-status-codes';
import { schema } from 'yaschema';
import { makeHttpApi } from 'yaschema-api';

import { genericFailureSchemas } from '../../../types/GenericFailure';
import { optionalAuthHeadersSchema } from '../../auth/types/AuthHeaders';
import { conversationSchema } from '../types/Conversation';

export const GET = makeHttpApi({
  method: 'GET',
  routeType: 'conversation',
  credentials: 'include',
  url: '/conversations/{conversationId}',
  isSafeToRetry: true,
  schemas: {
    request: {
      headers: optionalAuthHeadersSchema,
      params: schema.object({ conversationId: schema.string() })
    },
    successResponse: {
      status: schema.number(StatusCodes.OK),
      headers: optionalAuthHeadersSchema,
      body: conversationSchema
    },
    failureResponse: genericFailureSchemas
  }
});
