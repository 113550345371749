import { useEffect } from 'react';
import { useBinding } from 'react-bindings';

export const useIsMountedBinding = () => {
  const isMounted = useBinding(() => false, { id: 'isMounted', detectChanges: true });

  useEffect(() => {
    isMounted.set(true);

    return () => {
      isMounted.set(false);
    };
  });

  return isMounted;
};
