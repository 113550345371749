import { StatusCodes } from 'http-status-codes';
import { schema } from 'yaschema';
import { makeHttpApi } from 'yaschema-api';

import { genericFailureSchemas } from '../../../../types/GenericFailure';
import { optionalAuthHeadersSchema } from '../../../auth/types/AuthHeaders';

export const POST = makeHttpApi({
  method: 'POST',
  routeType: 'conversation',
  credentials: 'include',
  url: '/conversations/{conversationId}/messages',
  schemas: {
    request: {
      headers: optionalAuthHeadersSchema,
      params: schema.object({ conversationId: schema.string() }),
      body: schema.object({
        /** Only allowed in non-prod environments */
        sender: schema.string().allowNull().optional(),
        content: schema.string(),
        attachmentUrls: schema.array({ items: schema.string() }).optional()
      })
    },
    successResponse: {
      status: schema.number(StatusCodes.OK),
      headers: optionalAuthHeadersSchema,
      body: schema.object({
        messageId: schema.string()
      })
    },
    failureResponse: genericFailureSchemas
  }
});
