import AddIcon from '@mui/icons-material/AddCircleOutline';
import MenuIcon from '@mui/icons-material/Menu';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import type { SxProps, Theme } from '@mui/material';
import { AppBar, Box, Button, Drawer, Toolbar, Typography, useTheme } from '@mui/material';
import _ from 'lodash';
import type { api } from 'msg-helper-demo-schema';
import { BindingsConsumer, useBinding, useBindingEffect, useCallbackRef, useDerivedBinding } from 'react-bindings';
import { useDerivedWaitable, WaitablesConsumer } from 'react-waitables';

import { ControlledSwitch } from '../components/ControlledSwitch';
import { ControlledTextField } from '../components/ControlledTextField';
import { Conversation } from '../components/Conversation';
import { ConversationList } from '../components/ConversationList';
import { ConversationParticipantDisplayTextField } from '../components/ConversationParticipantDisplayTextField';
import { EmptyConversation } from '../components/EmptyConversation';
import { InputSuggestionsBar } from '../components/InputSuggestionsBar';
import { MediaSizeDetector } from '../components/MediaSizeDetector';
import { QuestionsList } from '../components/QuestionsList';
import { TextInputBar } from '../components/TextInputBar';
import { getCachedAppConfig } from '../config/get-app-config';
import { recheckAuth } from '../context/Authentication';
import { useConversationsStream } from '../streaming/hooks/useConversationsStream';
import { signOut } from '../tasks/auth/signOut';
import { addMessageToConversation } from '../tasks/conversations/addMessageToConversation';
import { createConversation } from '../tasks/conversations/createConversation';
import { deleteConversation } from '../tasks/conversations/deleteConversation';
import { getConversationInfo } from '../tasks/conversations/getConversationInfo';
import { updateQuestionAnswers } from '../tasks/questions/updateQuestionAnswers';
import { doAsync } from '../utils/do-async';
import { switcher } from '../utils/switcher';

const drawerSx: SxProps<Theme> = {
  minWidth: '240px',
  maxWidth: '320px',
  width: '80vw',
  '& .MuiDrawer-paper': {
    minWidth: '240px',
    maxWidth: '320px',
    width: '80vw',
    boxSizing: 'border-box'
  }
};

export const ChatScreen = () => {
  const appConfig = getCachedAppConfig();

  const interestCountsByConversationId = useBinding<Partial<Record<string, number>>>(() => ({}), {
    id: 'interestCountsByConversationId',
    detectChanges: false
  });

  useConversationsStream({ interestCountsByConversationId });

  const theme = useTheme();
  const isMdOrLarger = useBinding(() => false, { id: '' });
  const openDrawer = useBinding(() => false, { id: 'openDrawer' });

  const participants = useBinding<string>(() => '', { id: 'participants', detectChanges: true });
  const sender = useBinding(() => false, { id: 'sender', detectChanges: true });
  const message = useBinding<string>(() => '', { id: 'message', detectChanges: true });
  const selectedConversationId = useBinding<string | undefined>(() => undefined, { id: 'selectedConversationId', detectChanges: true });

  const answers = useBinding<Partial<Record<string, string>>>(() => ({}), { id: 'answers' });

  const isSpecialConversationIdSelected = useDerivedBinding(
    selectedConversationId,
    (selectedConversationId) => selectedConversationId?.startsWith('special:'),
    { id: 'isSpecialConversationIdSelected', limitType: 'none' }
  );
  const selectedConversation = useDerivedWaitable(
    selectedConversationId,
    async (conversationId, _dependencies, setFailure): Promise<api.conversations.Conversation | null | undefined> => {
      if (conversationId === undefined || conversationId.startsWith('special:')) {
        return null;
      }

      const res = await getConversationInfo({ conversationId });
      if (!res.ok) {
        setFailure(res);
        return;
      }

      return res.value;
    },
    { id: 'selectedConversation', lockedWhile: isSpecialConversationIdSelected }
  );

  const selectedConversationTitle = useDerivedWaitable(
    { selectedConversationId, selectedConversation: selectedConversation.value },
    ({ selectedConversationId, selectedConversation }) => {
      if (selectedConversationId === 'special:questions') {
        return 'Questions';
      } else if (selectedConversationId === undefined || selectedConversation === null || selectedConversation === undefined) {
        return 'Msg Helper';
      } else {
        return (
          <Box sx={{ display: 'flex', overflowX: 'auto', flexGrow: 1, maxWidth: '100%' }}>
            {selectedConversation.participants.map((p) => (
              <Box key={p.primary} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                <ConversationParticipantDisplayTextField conversationId={selectedConversationId} primary={p.primary} display={p.display} />
                <Typography color="white" variant="caption" noWrap={true}>
                  {p.primary}
                </Typography>
              </Box>
            ))}
          </Box>
        );
      }
    },
    { id: 'selectedConversationTitle' }
  );

  useBindingEffect(
    selectedConversationId,
    (selectedConversationId) =>
      interestCountsByConversationId.set(
        selectedConversationId === undefined || selectedConversationId.startsWith('special:') ? {} : { [selectedConversationId]: 1 }
      ),
    { triggerOnMount: true }
  );

  const onMenuButtonClick = useCallbackRef(() => openDrawer.set(!openDrawer.get()));

  const onNewConversationClick = useCallbackRef(() => selectedConversationId.reset());

  const onDeleteConversationClick = useCallbackRef(async () => {
    const conversationId = selectedConversationId.get();
    if (conversationId === undefined) {
      return; // Nothing to do
    }

    const deleted = await deleteConversation({ conversationId });
    if (!deleted.ok) {
      console.error('Something went wrong', deleted);
    }

    selectedConversationId.reset();
  });

  const onSaveAnswersClick = useCallbackRef(async () => {
    const saved = await updateQuestionAnswers({ answers: answers.get() });
    if (!saved.ok) {
      // TODO: TEMP
      console.error('Failed to save answers');
    }
  });

  const onInputSubmit = useCallbackRef(async (content: string) => {
    const conversationId = selectedConversationId.get();
    if (conversationId === undefined) {
      const participantPrimary = participants.get().trim();
      if (participantPrimary.length === 0) {
        return; // Not ready
      }

      const conversation = await createConversation({
        participants: [
          {
            primary: participantPrimary,
            contactId: null,
            display: participantPrimary
          }
        ],
        content
      });
      if (!conversation.ok) {
        console.error('Something went wrong', conversation);
        return;
      }

      if (conversationId === undefined) {
        selectedConversationId.set(conversation.value.conversationId);
      }
    } else {
      const participantPrimary = selectedConversation.value.get()?.participants[0]?.primary;
      const theSender = appConfig.CONFIG_ENV === 'local' ? (sender.get() ? participantPrimary : null) : undefined;
      const conversation = await addMessageToConversation({ sender: theSender, conversationId, content });
      if (!conversation.ok) {
        console.error('Something went wrong', conversation);
        return;
      }
    }

    message.reset();
  });

  const onSignOutClick = useCallbackRef(() => {
    doAsync(async () => {
      await signOut();

      recheckAuth('hard');
    });
  });

  return (
    <>
      <MediaSizeDetector query={theme.breakpoints.up('md')} matches={isMdOrLarger} />
      <Box sx={{ display: 'flex', alignItems: 'stretch', flexGrow: 1, maxWidth: '100%' }}>
        <BindingsConsumer bindings={{ isMdOrLarger, openDrawer }}>
          {({ isMdOrLarger, openDrawer }) => (
            <Drawer
              anchor="left"
              variant={isMdOrLarger ? 'permanent' : 'temporary'}
              open={isMdOrLarger ? true : openDrawer}
              onClose={onMenuButtonClick}
              sx={drawerSx}
            >
              <AppBar position="relative">
                <Toolbar>
                  <Typography variant="h6" noWrap={true} sx={{ flexGrow: 1 }}>
                    Conversations
                  </Typography>
                  <Button color="inherit" onClick={onNewConversationClick}>
                    <AddIcon />
                  </Button>
                </Toolbar>
              </AppBar>
              <Box sx={{ flexGrow: 1 }}>
                <ConversationList selectedConversationId={selectedConversationId} />
              </Box>
              <AppBar position="relative">
                <Toolbar>
                  <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <Button color="warning" variant="text" onClick={onSignOutClick}>
                      Sign Out
                    </Button>
                  </Box>
                </Toolbar>
              </AppBar>
            </Drawer>
          )}
        </BindingsConsumer>
        <main style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
          <AppBar position="relative">
            <Toolbar>
              <BindingsConsumer bindings={isMdOrLarger}>
                {(isMdOrLarger) =>
                  !isMdOrLarger ? (
                    <Button color="inherit" onClick={onMenuButtonClick}>
                      <MenuIcon />
                    </Button>
                  ) : null
                }
              </BindingsConsumer>
              <BindingsConsumer bindings={selectedConversationId}>
                {(selectedConversationId) =>
                  selectedConversationId !== undefined ? (
                    <>
                      <Typography variant="h5" noWrap={true} sx={{ flexGrow: 1 }}>
                        <WaitablesConsumer dependencies={selectedConversationTitle}>
                          {(selectedConversationTitle) => selectedConversationTitle}
                        </WaitablesConsumer>
                      </Typography>

                      {!selectedConversationId.startsWith('special:') ? (
                        <Button color="error" onClick={onDeleteConversationClick}>
                          <RemoveIcon />
                        </Button>
                      ) : null}
                    </>
                  ) : (
                    <ControlledTextField
                      value={participants}
                      variant="filled"
                      placeholder="Phone Number"
                      sx={{
                        flexGrow: 1,
                        overflow: 'hidden',
                        height: '36px',
                        '& .MuiInputBase-root': { overflow: 'hidden', backgroundColor: 'white', borderRadius: '4px' }
                      }}
                      hiddenLabel={true}
                    />
                  )
                }
              </BindingsConsumer>
            </Toolbar>
          </AppBar>
          <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
            <BindingsConsumer bindings={selectedConversationId}>
              {(selectedConversationId) =>
                switcher(
                  selectedConversationId ?? '',
                  {
                    '': () => <EmptyConversation />,
                    'special:questions': () => <QuestionsList answers={answers} />
                  },
                  (v) => <Conversation conversationId={v} />
                )
              }
            </BindingsConsumer>
          </Box>
          <BindingsConsumer bindings={selectedConversationId}>
            {(selectedConversationId) =>
              switcher(
                selectedConversationId ?? '',
                {
                  'special:questions': () => (
                    <AppBar color="secondary" position="relative">
                      <Toolbar sx={{ justifyContent: 'flex-end' }}>
                        <Button color="primary" variant="contained" onClick={onSaveAnswersClick}>
                          Save
                        </Button>
                      </Toolbar>
                    </AppBar>
                  )
                },
                (selectedConversationId) => (
                  <AppBar color="secondary" position="relative">
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', flexGrow: 1 }}>
                      {selectedConversationId !== undefined ? (
                        <InputSuggestionsBar input={message} conversationId={selectedConversationId} />
                      ) : null}
                      {appConfig.CONFIG_ENV === 'local' ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <Typography variant="body1">Send as: self</Typography>
                          <ControlledSwitch value={sender} /> <Typography variant="body1">other person</Typography>
                        </Box>
                      ) : null}
                      <TextInputBar input={message} onSubmit={onInputSubmit} />
                    </Box>
                  </AppBar>
                )
              )
            }
          </BindingsConsumer>
        </main>
      </Box>
    </>
  );
};
