import { StatusCodes } from 'http-status-codes';
import { schema } from 'yaschema';
import { makeHttpApi } from 'yaschema-api';

import { genericFailureSchemas } from '../../types/GenericFailure';
import { orderDirSchema } from '../../types/OrderDir';
import { optionalAuthHeadersSchema } from '../auth/types/AuthHeaders';
import { conversationSchema } from './types/Conversation';
import { conversationOrderBySchema } from './types/ConversationOrderBy';

export const getConversationsContinuationQuerySchema = schema.object({
  searchToken: schema.string()
});
export type GetConversationsContinuationQuery = typeof getConversationsContinuationQuerySchema.valueType;

export const getConversationsNewQuerySchema = schema.object({
  /** @defaultValue `"last-update-time"` */
  orderBy: conversationOrderBySchema.optional(),
  /** @defaultValue `"asc"` */
  orderDir: orderDirSchema.optional(),
  /**
   * Use positive values to limit from start of known values, use negative values to limit from end of known values.
   *
   * @defaultValue `0`
   */
  offset: schema.number().optional(),
  /** @defaultValue `10` */
  limit: schema.number().optional()
});
export type GetConversationsNewQuery = typeof getConversationsNewQuerySchema.valueType;

/** By default returns the most recently updated conversations first */
export const GET = makeHttpApi({
  method: 'GET',
  routeType: 'conversation',
  credentials: 'include',
  url: '/conversations',
  isSafeToRetry: true,
  schemas: {
    request: {
      headers: optionalAuthHeadersSchema,
      query: schema.oneOf(getConversationsContinuationQuerySchema, getConversationsNewQuerySchema).optional()
    },
    successResponse: {
      status: schema.number(StatusCodes.OK),
      headers: optionalAuthHeadersSchema,
      body: schema.object({
        conversations: schema.array({ items: conversationSchema }),
        nextSearchToken: schema.string()
      })
    },
    failureResponse: genericFailureSchemas
  }
});
