import { api } from 'msg-helper-demo-schema';
import type { WrappedResult } from 'react-waitables';
import { apiFetch } from 'yaschema-api-fetcher';

export const addMessageToConversation = async ({
  sender,
  conversationId,
  content
}: {
  sender?: string | null;
  conversationId: string;
  content: string;
}): Promise<WrappedResult<{ conversationId: string }>> => {
  const res = await apiFetch(api.conversations.id.messages.POST, { params: { conversationId }, body: { sender, content } });
  if (!res.ok) {
    return { ok: false, value: res };
  }

  return { ok: true, value: { conversationId } };
};
