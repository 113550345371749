import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#5674bd',
      main: '#4664ad',
      dark: '#36549d',
      contrastText: '#fff'
    },
    secondary: {
      light: '#eee',
      main: '#ddd',
      dark: '#ccc',
      contrastText: '#000'
    }
  }
});
