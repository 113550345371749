import { StatusCodes } from 'http-status-codes';
import { schema } from 'yaschema';
import { makeHttpApi } from 'yaschema-api';

import { genericFailureSchemas } from '../../types/GenericFailure';
import { optionalAuthHeadersSchema } from './types/AuthHeaders';
import { authTokenDeliveryModeSchema } from './types/AuthTokenDeliveryMode';

export const POST = makeHttpApi({
  method: 'POST',
  routeType: 'auth',
  credentials: 'include',
  url: '/auth',
  schemas: {
    request: {
      body: schema.object({
        username: schema.string(),
        password: schema.string(),
        authTokenDeliveryMode: authTokenDeliveryModeSchema
      })
    },
    successResponse: {
      status: schema.number(StatusCodes.OK),
      headers: optionalAuthHeadersSchema,
      body: schema.object({
        /** If `true`, the auth token isn't verified yet and multi-factor authentication is required before most APIs can be used */
        requiresMfa: schema.boolean()
      })
    },
    failureResponse: genericFailureSchemas
  }
});
