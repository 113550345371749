import { schema } from 'yaschema';

export const conversationParticipantSchema = schema.object({
  /** The phone number, email address, or other ID, etc. used to reach the participant */
  primary: schema.string(),
  /** Use if synced with an address book (ex. Apple Contacts API) */
  contactId: schema.string().allowNull(),
  /** What to show (ex. contact first and last name or formatted phone number) */
  display: schema.string()
});
export type ConversationParticipant = typeof conversationParticipantSchema.valueType;
