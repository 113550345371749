import { Box, Button, Card, CircularProgress, Grid, ListItemButton, ListItemText, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import type { FormEvent } from 'react';
import { BindingsConsumer, useBinding, useBindingEffect, useCallbackRef, useDerivedBinding } from 'react-bindings';
import { useDerivedWaitable, useWaitableFunction, WaitablesConsumer } from 'react-waitables';

import { ControlledTextField } from '../components/ControlledTextField';
import { ONE_SEC_MSEC } from '../consts/time';
import { recheckAuth } from '../context/Authentication';
import { signOut } from '../tasks/auth/signOut';
import { activateTotp } from '../tasks/auth/totp/activateTotp';
import { createTotp } from '../tasks/auth/totp/createTotp';
import { listTotps } from '../tasks/auth/totp/listTotps';
import { verifyTotp } from '../tasks/auth/totp/verifyTotp';
import { doAsync } from '../utils/do-async';

export const MfaScreen = () => {
  const { enqueueSnackbar } = useSnackbar();

  const totps = useWaitableFunction(listTotps, { id: 'totps' });
  const hasAnyActiveTotps = useDerivedWaitable(totps, (totps) => totps.find((totp) => totp.isActive) !== undefined, {
    id: 'hasAnyActiveTotps'
  });
  const displayTotps = useDerivedWaitable(
    { totps, hasAnyActiveTotps },
    ({ totps, hasAnyActiveTotps }) => (hasAnyActiveTotps ? totps.filter((totp) => totp.isActive) : []),
    { id: 'displayTotps' }
  );

  const newTotp = useBinding<{ totpId: string; secret: string } | 'locked' | undefined>(() => undefined, {
    id: 'newTotp',
    detectChanges: true
  });
  useBindingEffect(
    { hasAnyActiveTotps: hasAnyActiveTotps.value, newTotp },
    ({ hasAnyActiveTotps, newTotp }, { newTotp: newTotpBinding }) => {
      if (hasAnyActiveTotps === false && newTotp === undefined) {
        newTotpBinding.set('locked');
        doAsync(async () => {
          const res = await createTotp();
          if (res.ok) {
            newTotpBinding.set(res.value);
          } else {
            newTotpBinding.set(undefined);
          }
        });
      }
    }
  );

  const selectedTotpId = useBinding<string | undefined>(() => undefined, { id: 'selectedTotpId', detectChanges: true });
  const name = useBinding<string>(() => '', { id: 'name', detectChanges: true });
  const passcode = useBinding<string>(() => '', { id: 'passcode', detectChanges: true });

  const totpSubmissionBlockedUntilTimeMSec = useBinding(() => 0, { id: 'totpSubmissionBlockedUntilTimeMSec' });
  const isTotpSubmissionBlocked = useDerivedBinding(
    totpSubmissionBlockedUntilTimeMSec,
    (blockedUntilTimeMSec) => Date.now() < blockedUntilTimeMSec,
    { id: 'isTotpSubmissionBlocked', detectInputChanges: false }
  );

  useBindingEffect(totps.value, (totps) => {
    if (totps?.length === 1 && totps[0].isActive) {
      selectedTotpId.set(totps[0].totpId);
    }
  });

  const submit = useCallbackRef(() =>
    doAsync(async () => {
      // TODO: do real form validation

      if (hasAnyActiveTotps.value.get()) {
        const theSelectedTotpId = selectedTotpId.get();
        if (theSelectedTotpId === undefined) {
          return; // Not ready
        }

        const checked = await verifyTotp({ totpId: theSelectedTotpId, passcode: passcode.get() });
        if (!checked.ok) {
          enqueueSnackbar({ message: 'TOTP Incorrect.  You may try again in a few seconds.', variant: 'error' });
          passcode.set('');

          const intervalMSec = 5 * ONE_SEC_MSEC;
          totpSubmissionBlockedUntilTimeMSec.set(Date.now() + intervalMSec);
          setTimeout(() => totpSubmissionBlockedUntilTimeMSec.set(totpSubmissionBlockedUntilTimeMSec.get()), intervalMSec + 1);

          return;
        }
      } else {
        const theNewTotp = newTotp.get();
        if (theNewTotp === undefined || theNewTotp === 'locked') {
          return; // Not ready
        }

        const checked = await activateTotp({ totpId: theNewTotp.totpId, name: name.get(), passcode: passcode.get() });
        if (!checked.ok) {
          // TODO: TEMP
          console.error('Verification failed');
          return;
        }
      }

      recheckAuth('hard');
    })
  );

  const onSubmit = useCallbackRef((event: FormEvent) => {
    event.preventDefault();
    submit();
  });

  const onTotpOptionClick = (totpId: string) => () => selectedTotpId.set(totpId);

  const onSignOutClick = useCallbackRef(() => {
    doAsync(async () => {
      await signOut();

      recheckAuth('hard');
    });
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box',
        margin: 2,
        padding: 2,
        flexGrow: 1,
        maxWidth: '100%'
      }}
    >
      <Card variant="outlined" sx={{ padding: 2, width: '100%', maxWidth: '960px' }}>
        <form onSubmit={onSubmit}>
          <WaitablesConsumer dependencies={{ displayTotps, selectedTotpId }} ifLoading={() => <CircularProgress size={32} />}>
            {({ displayTotps, selectedTotpId }) =>
              displayTotps.length > 1
                ? displayTotps.map((totp) => (
                    <ListItemButton key={totp.totpId} selected={selectedTotpId === totp.totpId} onClick={onTotpOptionClick(totp.totpId)}>
                      <ListItemText primary={totp.name} />
                    </ListItemButton>
                  ))
                : null
            }
          </WaitablesConsumer>
          <BindingsConsumer bindings={newTotp}>
            {(newTotp) =>
              newTotp !== undefined ? (
                newTotp === 'locked' ? (
                  <CircularProgress size={32} />
                ) : (
                  <Typography variant="h5">Secret: {newTotp.secret}</Typography>
                )
              ) : null
            }
          </BindingsConsumer>

          <Typography variant="h4">TOTP</Typography>
          <Grid container spacing={2} sx={{ alignItems: 'center' }}>
            <BindingsConsumer bindings={newTotp}>
              {(newTotp) =>
                newTotp !== undefined && newTotp !== 'locked' ? (
                  <>
                    <Grid item xs={4}>
                      <Typography variant="body1">Name</Typography>
                    </Grid>
                    <Grid item xs={8} sx={{ display: 'flex' }}>
                      <ControlledTextField value={name} sx={{ flexGrow: 1 }} />
                    </Grid>
                  </>
                ) : null
              }
            </BindingsConsumer>
            <Grid item xs={4}>
              <Typography variant="body1">Passcode</Typography>
            </Grid>
            <Grid item xs={8} sx={{ display: 'flex' }}>
              <ControlledTextField value={passcode} type="password" autoComplete="one-time-code" sx={{ flexGrow: 1 }} />
            </Grid>
          </Grid>

          <Box sx={{ display: 'flex', paddingTop: 2, width: '100%', justifyContent: 'flex-end' }}>
            <BindingsConsumer bindings={isTotpSubmissionBlocked}>
              {(isTotpSubmissionBlocked) => (
                <Button type="submit" variant="contained" disabled={isTotpSubmissionBlocked} sx={{ minWidth: '10em' }}>
                  Continue
                </Button>
              )}
            </BindingsConsumer>
          </Box>
        </form>
      </Card>
      <Button variant="text" color="warning" onClick={onSignOutClick}>
        Sign Out
      </Button>
    </Box>
  );
};
