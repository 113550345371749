import { schema } from 'yaschema';

import type { OptionalAuthHeaders } from '../apis/auth/types/AuthHeaders';
import { optionalAuthHeadersSchema } from '../apis/auth/types/AuthHeaders';

export const genericFailureSchemas = {
  status: schema.number().not(schema.number(200)),
  headers: optionalAuthHeadersSchema,
  body: schema.string().allowEmptyString().optional()
};
export interface GenericFailure {
  status: number;
  headers?: OptionalAuthHeaders;
  body?: string;
}
