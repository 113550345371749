import { StatusCodes } from 'http-status-codes';
import { schema } from 'yaschema';
import { makeHttpApi } from 'yaschema-api';

import { genericFailureSchemas } from '../../../../types/GenericFailure';
import { orderDirSchema } from '../../../../types/OrderDir';
import { optionalAuthHeadersSchema } from '../../../auth/types/AuthHeaders';
import { messageSchema } from '../../types/Message';

export const getConversationMessagesContinuationQuerySchema = schema.object({
  searchToken: schema.string()
});
export type GetConversationMessagesContinuationQuery = typeof getConversationMessagesContinuationQuerySchema.valueType;

export const getConversationMessagesNewQuerySchema = schema.object({
  /** @defaultValue `"asc"` */
  orderDir: orderDirSchema.optional(),
  /**
   * Use positive values to limit from start of known values, use negative values to limit from end of known values.
   *
   * @defaultValue `0`
   */
  offset: schema.number().optional(),
  /** @defaultValue `10` */
  limit: schema.number().optional()
});
export type GetConversationMessagesNewQuery = typeof getConversationMessagesNewQuerySchema.valueType;

/** By default returns the most recent messages first */
export const GET = makeHttpApi({
  method: 'GET',
  routeType: 'conversation',
  credentials: 'include',
  url: '/conversations/{conversationId}/messages',
  isSafeToRetry: true,
  schemas: {
    request: {
      headers: optionalAuthHeadersSchema,
      params: schema.object({ conversationId: schema.string() }),
      query: schema.oneOf(getConversationMessagesContinuationQuerySchema, getConversationMessagesNewQuerySchema).optional()
    },
    successResponse: {
      status: schema.number(StatusCodes.OK),
      headers: optionalAuthHeadersSchema,
      body: schema.object({
        messages: schema.array({ items: messageSchema }),
        nextSearchToken: schema.string()
      })
    },
    failureResponse: genericFailureSchemas
  }
});
