import _ from 'lodash';
import type { WrappedResult } from 'react-waitables';

import { CONFIG_ENV } from '../consts/app-config-defaults';
import { makeAsyncUncheckedAccess } from '../utils/make-async-unchecked-access';
import { appConfigs } from './app-configs';
import type { AppConfig } from './types/AppConfig';
import { appConfigSchema } from './types/AppConfig';

let globalCachedAppConfig: AppConfig;

export const getCachedAppConfig = () => globalCachedAppConfig;

export const getAppConfig = _.once(async (): Promise<WrappedResult<AppConfig>> => {
  const baseConfig = appConfigs[CONFIG_ENV];
  if (baseConfig === undefined) {
    return { ok: false, value: `Missing app config for ${CONFIG_ENV}` };
  }

  const serializedConfig: Record<string, any> = { ...baseConfig };

  const deserializationResult = appConfigSchema.deserialize(serializedConfig, {
    okToMutateInputValue: true,
    removeUnknownKeys: true,
    validation: 'hard'
  });
  if (deserializationResult.error !== undefined) {
    return { ok: false, value: deserializationResult.error };
  }

  globalCachedAppConfig = deserializationResult.deserialized;

  return { ok: true, value: deserializationResult.deserialized };
});

export const getUncheckedAppConfig = makeAsyncUncheckedAccess(getAppConfig);
