import { ListItem, ListItemText } from '@mui/material';
import type { api } from 'msg-helper-demo-schema';
import type { Binding } from 'react-bindings';
import { useBinding, useBindingEffect } from 'react-bindings';

import { ControlledTextField } from './ControlledTextField';

export interface QuestionListItemProps {
  question: api.questions.Question;
  answers: Binding<Partial<Record<string, string>>>;
}

export const QuestionListItem = ({ question, answers }: QuestionListItemProps) => {
  const answer = useBinding(() => answers.get()[question.key] ?? '', { id: 'answer', deps: [question.key] });

  useBindingEffect(answer, () => {
    const theAnswers = answers.get();
    theAnswers[question.key] = answer.get();
    answers.set(theAnswers);
  });

  return (
    <ListItem sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
      <ListItemText primary={question.localizedQuestion} secondary={question.localizedInstructions} />
      <ControlledTextField multiline={true} value={answer} sx={{ width: '100%' }} />
    </ListItem>
  );
};
