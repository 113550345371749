import { api } from 'msg-helper-demo-schema';
import type { WrappedResult } from 'react-waitables';
import { apiFetch } from 'yaschema-api-fetcher';

export interface SignInResult {
  requiresMfa: boolean;
}

export const signIn = async ({ username, password }: { username: string; password: string }): Promise<WrappedResult<SignInResult>> => {
  const res = await apiFetch(api.auth.POST, { body: { username, password, authTokenDeliveryMode: 'cookie' as const } });

  if (!res.ok) {
    return { ok: false, value: undefined };
  }

  return { ok: true, value: { requiresMfa: res.body.requiresMfa } };
};
