import { api } from 'msg-helper-demo-schema';
import type { WrappedResult } from 'react-waitables';
import { apiFetch } from 'yaschema-api-fetcher';

export const updateParticipantDisplay = async ({
  conversationId,
  participantPrimary,
  newParticipantDisplay
}: {
  conversationId: string;
  participantPrimary: string;
  newParticipantDisplay: string;
}): Promise<WrappedResult<undefined>> => {
  const res = await apiFetch(api.conversations.id.updateParticipantDisplay.POST, {
    params: { conversationId },
    body: { participantPrimary, newParticipantDisplay }
  });
  if (!res.ok) {
    return { ok: false, value: res };
  }

  return { ok: true, value: undefined };
};
