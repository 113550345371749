import { api } from 'msg-helper-demo-schema';
import { useRef } from 'react';
import type { ReadonlyBinding } from 'react-bindings';
import { useBindingEffect } from 'react-bindings';

import { useConversationChangeNotifier } from '../../context/ConversationChange';
import { useConversationMessagesCache } from '../../context/ConversationMessagesCache';
import { useConversationMessageSuggestionsCache } from '../../context/ConversationMessageSuggestionsCache';
import { useConversationsCache } from '../../context/ConversationsCache';
import { useApiWs } from '../../hooks/use-api-ws';
import { getStreamingToken } from '../../tasks/auth/getStreamingToken';
import { oneAsyncAtATime } from '../../utils/one-async-at-a-time';

export const useConversationsStream = async ({
  interestCountsByConversationId
}: {
  interestCountsByConversationId: ReadonlyBinding<Partial<Record<string, number>>>;
}) => {
  const conversationsCache = useConversationsCache();
  const conversationMessagesCache = useConversationMessagesCache();
  const conversationMessageSuggestionsCache = useConversationMessageSuggestionsCache();
  const conversationChangeNotifier = useConversationChangeNotifier();

  const connectionState = useRef({
    isAuthorized: false,
    subscribedConversations: new Set<string>()
  });

  const connection = useApiWs(
    api.conversations.stream,
    {},
    {
      authorization: async ({ input, output }) => {
        if (connectionState.current.isAuthorized === input.authorized) {
          return;
        }

        connectionState.current.isAuthorized = input.authorized;

        await Promise.all([
          updateConversationSubscriptions(),
          connectionState.current.isAuthorized ? output.streamConversations({}) : undefined
        ]);
      },
      conversationDeleted: async ({ input }) => {
        delete conversationsCache.conversations[input.conversationId];
        delete conversationMessagesCache[input.conversationId];
        conversationChangeNotifier(input.conversationId);
      },
      conversations: async ({ input }) => {
        if (!conversationsCache.isLoaded) {
          conversationsCache.isLoaded = true;
          conversationChangeNotifier('');
        }
        for (const conversation of input.conversations) {
          conversationsCache.conversations[conversation.id] = conversation;
          conversationChangeNotifier(conversation.id);
        }
      },
      conversationMessageDeleted: async ({ input }) => {
        const messagesById = conversationMessagesCache[input.conversationId];
        if (messagesById !== undefined) {
          delete messagesById[input.messageId];
        }
        conversationChangeNotifier(input.conversationId);
      },
      conversationMessages: async ({ input }) => {
        conversationMessagesCache[input.conversationId] = conversationMessagesCache[input.conversationId] ?? {};

        const messagesById = conversationMessagesCache[input.conversationId]!;
        for (const message of input.messages) {
          messagesById[message.id] = message;
        }

        conversationChangeNotifier(input.conversationId);
      },
      conversationMessageSuggestions: async ({ input }) => {
        if (input.conversationSeq < (conversationsCache.conversations[input.conversationId]?.userSeq ?? -1)) {
          return; // Ignoring since these suggestions were for an older form of the chat messages
        }

        conversationMessageSuggestionsCache[input.conversationId] = {
          conversationSeq: input.conversationSeq,
          suggestions: input.suggestions
        };

        conversationChangeNotifier(input.conversationId);
      },
      badRequest: async ({ input }) => {
        console.log('bad request', input.message);
      },
      forbidden: async ({ input }) => {
        console.log('forbidden', input.message);
      },
      internalServerError: async ({ input }) => {
        console.log('internal server error', input.message);
      },
      unauthorized: async ({ input }) => {
        console.log('unauthorized', input.message);
      }
    },
    {
      onConnect: async ({ output }) => {
        console.log('GOT onConnect');

        const streamingAuthToken = await getStreamingToken();
        if (!streamingAuthToken.ok) {
          console.error('Failed to get streaming auth token');
          return;
        }

        await output.authorize({ streamingAuthToken: streamingAuthToken.value });
      },
      onDisconnect: async () => {
        console.log('GOT onDisconnect');

        connectionState.current.isAuthorized = false;
        connectionState.current.subscribedConversations.clear();
      },
      onError: async () => {
        console.log('GOT onError');
      }
    },
    { limitMSec: 10 }
  );

  const updateConversationSubscriptions = oneAsyncAtATime(async () => {
    const theConnection = connection.value.get();
    if (theConnection === undefined) {
      return;
    }

    if (!connectionState.current.isAuthorized) {
      connectionState.current.subscribedConversations.clear();
      return;
    }

    const theInterestCountsByConversationId = interestCountsByConversationId.get();
    const combinedConversationIds = Array.from(
      new Set([...connectionState.current.subscribedConversations, ...Object.keys(theInterestCountsByConversationId)])
    );

    await Promise.all(
      combinedConversationIds.map(async (conversationId) => {
        const count = theInterestCountsByConversationId[conversationId] ?? 0;

        if (count <= 0 && connectionState.current.subscribedConversations.has(conversationId)) {
          await theConnection.output.stopStreamingConversationMessages({ conversationId });
          connectionState.current.subscribedConversations.delete(conversationId);
        } else if (count > 0 && !connectionState.current.subscribedConversations.has(conversationId)) {
          await theConnection.output.streamConversationMessages({ conversationId });
          connectionState.current.subscribedConversations.add(conversationId);
        }
      })
    );
  });

  useBindingEffect(interestCountsByConversationId, updateConversationSubscriptions, { triggerOnMount: true });
};
