/** Wraps an async function such that it can't be called concurrently.  If a call is made while the function is already running, the
 * function will be run again after the current execution is completed.  If this is called multiple times while the function is already
 * running, those calls are deduped such that only a single call will be performed at the end of the current execution. */
export const oneAsyncAtATime = (func: () => Promise<void>) => {
  let isBusy = false;
  let needsAnotherExec = false;

  const run = async () => {
    if (isBusy) {
      // If we tried to call this again while it was busy, we should execute again once the current execution is complete
      needsAnotherExec = true;
      return;
    }

    isBusy = true;

    try {
      await func();
    } finally {
      isBusy = false;

      if (needsAnotherExec) {
        needsAnotherExec = false;
        await run();
      }
    }
  };

  return run;
};
